import PropTypes from "prop-types";
import {Controller} from "react-hook-form";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";

const CheckboxNullable = ({
  control,
  name,
  error,
  rules,
  type = "checkbox",
  disabled = false,
  labels = {yes: "Yes", no: "No"},
}) => {
  return (
    <Controller
      rules={rules}
      render={({field: {onChange, value, ref}}) => {
        const yes = {checked: value === true};
        const no = {checked: value === false};
        return (
          <>
            <div>
              <FormGroup
                check
                inline
                className="form-check-alt form-check-lg !mb-0"
              >
                <Input
                  innerRef={ref}
                  id={`${name}Yes`}
                  name={`${name}Yes`}
                  type={type}
                  data-test={`${name}Yes`}
                  invalid={!!error}
                  disabled={disabled}
                  {...yes}
                  onChange={() => {
                    if (document.getElementById(`${name}Yes`).checked) {
                      onChange(true);
                    }
                    if (
                      !document.getElementById(`${name}Yes`).checked &&
                      !document.getElementById(`${name}No`).checked
                    ) {
                      onChange(null);
                    }
                  }}
                />
                <Label check for={`${name}Yes`}>
                  {labels.yes}
                </Label>
              </FormGroup>
              <FormGroup
                check
                inline
                className="form-check-alt form-check-lg !mb-0"
              >
                <Input
                  id={`${name}No`}
                  data-test={`${name}No`}
                  type={type}
                  invalid={!!error}
                  disabled={disabled}
                  {...no}
                  onChange={() => {
                    if (document.getElementById(`${name}No`).checked) {
                      onChange(false);
                    }

                    if (
                      !document.getElementById(`${name}Yes`).checked &&
                      !document.getElementById(`${name}No`).checked
                    ) {
                      onChange(null);
                    }
                  }}
                />
                <Label check for={`${name}No`}>
                  {labels.no}
                </Label>
              </FormGroup>
            </div>
            {error && (
              <FormFeedback style={{display: "block"}}>
                {error.message}
              </FormFeedback>
            )}
          </>
        );
      }}
      control={control}
      name={name}
      defaultValue=""
    />
  );
};

CheckboxNullable.propTypes = {
  type: PropTypes.string,
  control: PropTypes.object,
  name: PropTypes.string.isRequired,
  error: PropTypes.object,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  labels: PropTypes.object,
};
export default CheckboxNullable;
